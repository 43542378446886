// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-user-js": () => import("/opt/build/repo/src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */),
  "component---src-templates-voyage-js": () => import("/opt/build/repo/src/templates/voyage.js" /* webpackChunkName: "component---src-templates-voyage-js" */),
  "component---src-pages-admin-js": () => import("/opt/build/repo/src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-story-js": () => import("/opt/build/repo/src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-trip-js": () => import("/opt/build/repo/src/templates/trip.js" /* webpackChunkName: "component---src-templates-trip-js" */),
  "component---src-pages-blogs-js": () => import("/opt/build/repo/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-how-it-works-js": () => import("/opt/build/repo/src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("/opt/build/repo/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

